import React, { Fragment, useContext, useEffect, useState } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"
import {
  GATSBY_PBM_ZENDESK_SUBDOMAIN,
  GATSBY_PBM_ZENDESK_API_KEY,
  GATSBY_PBM_ZENDESK_EMAIL,
} from "gatsby-env-variables"

import Layout from "layout/Layout"
import Section from "elements/Section"
import Message from "elements/Message"
import Container from "layout/Container"
import MedicineBox from "elements/MedicineBox"
import ActionButtons from "elements/ActionButtons"
import AddMedicinesSection from "./components/AddMedicinesSection"
import MedEnsureModal from "../MedEnsureModal"

import {
  zendeskUploadFiles,
  generateUploadFiles,
} from "services/zendeskService"
import { formSchema } from "../../utils/formSchema"
import { isBrowser } from "../../../../services/general"
import { isFutureDate } from "../../../../services/date"
import { AppContext } from "../../../../context/AppContext"
import { parseFormField } from "../../../../services/airtable"
import { generateFormField } from "elements/Form/services/form"
import { generateInitialValues } from "../../../../services/context"
import { useMedensureProducts } from "../../hooks/useMedensureProducts"
import { reformatAirtableData } from "../../services/reformatAirtableData"
import { getClientWebsite } from "../../utils/getClientWebsite"

const UpdatedPrescriptionForm = ({ pageContext, location }) => {
  const urlSearchParams = new URLSearchParams(location.search)
  const client = urlSearchParams.get("client") || ""
  const { state, dispatch } = useContext(AppContext)
  const [filesUploaded, setFilesUploaded] = useState(state.documents)
  const [showSearch, setShowSearch] = useState(
    state.updatedPrescription.medicines.length === 0
  )

  const data = useMedensureProducts({ client: client || "" })
  let medensureFormulary = data?.allAirtableMedEnsureFormulary?.nodes.map(
    (node) => node.data
  )
  medensureFormulary = medensureFormulary.map(reformatAirtableData)

  let company = isBrowser() ? sessionStorage.getItem("company") || "" : ""

  medensureFormulary = medensureFormulary.filter((medicine) =>
    medicine?.price_list?.name?.includes(
      company?.toLowerCase().replace("foundever", "sitel")
    )
  )

  medensureFormulary = medensureFormulary.reduce(
    (medicines, currentProduct) => {
      let currentDrug = medicines.find(
        (medicine) => medicine.drugName === currentProduct.drugName
      )
      if (!currentDrug) medicines = [...medicines, currentProduct]
      return medicines
    },
    []
  )

  let formFields = pageContext.formFields
  let validationSchema = formSchema({ fields: formFields })
  let sectionFormFields = parseFormField(formFields)
  sectionFormFields = sectionFormFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )

  let medensureServices = []
  if (isBrowser())
    medensureServices = JSON.parse(sessionStorage.getItem("medensureServices"))

  const handleSubmit = (values, { setErrors }) => {
    if (
      isFutureDate({
        date: `${values?.prescriptionDate?.month?.value} ${values?.prescriptionDate?.date?.value} ${values?.prescriptionDate?.year}`,
      })
    ) {
      setErrors({
        prescriptionDate: {
          month: "Please input a valid prescription date",
          date: "Please input a valid prescription date",
          year: "Please input a valid prescription date",
        },
      })
      return values
    }
    if (state?.updatedPrescription?.medicines?.length === 0) return values
    if (state?.documents?.length === 0) return values
    dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        updatedPrescription: {
          ...state.updatedPrescription,
          ...values,
          medicines: [...state.updatedPrescription.medicines],
        },
      },
    })

    switch (true) {
      case medensureServices.includes("Cancel refills"):
        navigate("/medensure/cancel-refills")
        return
      case medensureServices.includes("Book a free teleconsult"):
        navigate("/medensure/health-survey")
        return
      default:
        navigate("/medensure/summary")
        return
    }
  }

  let hasMultipleSegment =
    medensureServices.includes("Cancel refills") ||
    medensureServices.includes("Book a free teleconsult")

  useEffect(() => {
    let documentsWithNoToken =
      state.documents?.filter?.((document) => !document?.token)?.length || 0

    if (!state?.activeDocument && documentsWithNoToken > 0) {
      let currentDocumentWithNoToken = state.documents?.find?.(
        (document) => !document?.token
      )
      dispatch({
        type: "SAVE_ACTIVE_DOCUMENT",
        payload: currentDocumentWithNoToken,
      })
      uploadToZendesk({ currentDocument: currentDocumentWithNoToken })
    }
  }, [state.documents])

  const uploadToZendesk = async ({ currentDocument }) => {
    const UPLOADED_FILES = await zendeskUploadFiles([currentDocument], null, {
      apiUrl: GATSBY_PBM_ZENDESK_SUBDOMAIN,
      apiKey: GATSBY_PBM_ZENDESK_API_KEY,
      apiEmail: GATSBY_PBM_ZENDESK_EMAIL,
    })
    const GENERATED_FILE_TOKEN = await generateUploadFiles(UPLOADED_FILES)

    let newTempDocument = {
      ...currentDocument,
      token: GENERATED_FILE_TOKEN?.[0],
    }

    dispatch({
      type: "REMOVE_ACTIVE_DOCUMENT",
      payload: {
        ...newTempDocument,
      },
    })
  }

  const handleRemoveMedicine = (index) => {
    const medicineList = [...state.updatedPrescription.medicines]
    medicineList.splice(index, 1)
    dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        updatedPrescription: {
          ...state.updatedPrescription,
          medicines: medicineList,
        },
      },
    })
    if (medicineList.length === 0) setShowSearch(true)
  }

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })

    dispatch({
      type: "SHOW_MODAL",
      payload: {
        hideCloseButton: true,
        hideOnMobile: true,
        content: (
          <MedEnsureModal
            dispatch={dispatch}
            clientWebsite={getClientWebsite(company)}
          />
        ),
      },
    })
  }, [])

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
      pageContext={pageContext}
      path="/medensure"
      display={{ footer: false, helpCenterBanner: false }}
    >
      <Container isCentered>
        <Formik
          initialValues={{
            ...generateInitialValues({ fields: formFields }),
            ...state.updatedPrescription,
            uploadPrescription: [...(state?.documents || [])],
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, isValid, submitCount, errors }) => (
            <Form>
              {sectionFormFields.map((section) => (
                <Section
                  title={section?.section}
                  subtitle={section?.subtitle || ""}
                  id={section?.sectionId || ""}
                  className="mt-1 mb-3"
                >
                  {section?.message && (
                    <Message color={section?.messageColor || "light"}>
                      <div
                        className="content"
                        dangerouslySetInnerHTML={{
                          __html: section?.message,
                        }}
                      />
                    </Message>
                  )}{" "}
                  {section?.fields.map((field) => {
                    if (!field?.referenceAnswer) {
                      return (
                        <Fragment>
                          {generateFormField({
                            setFilesUploaded,
                            formFields: section?.fields,
                            formField: {
                              ...field,
                              disabled:
                                field.name === "company" || !!field.disabledz,
                            },
                            values,
                            setFieldValue,
                            context: {
                              state,
                              dispatch,
                            },
                            errors,
                            submitCount,
                          })}
                          {!!field?.addDividerAfterField && (
                            <hr className="has-background-light" />
                          )}
                        </Fragment>
                      )
                    }
                    return null
                  })}
                </Section>
              ))}
              <Section title={"Prescribed Medicines"} className="mt-1 mb-3">
                <Message color="warning">
                  To ensure accurate dispensing of your medicines, please input
                  all the medicines indicated in your prescription. We will only
                  refill the medicines that you have inputted and are found in
                  your prescription.
                </Message>
                {state?.updatedPrescription?.medicines?.map(
                  (medicine, index) => (
                    <MedicineBox
                      handleDelete={() => handleRemoveMedicine(index)}
                    >
                      <p className="is-size-5 has-text-primary has-text-weight-bold">
                        {medicine.molecule}
                      </p>
                      <div className="is-flex is-justify-content-space-between">
                        <p className="is-size-6">
                          {medicine?.molecule === "Vitamin B complex"
                            ? ""
                            : medicine.strength}{" "}
                          {medicine.form}
                        </p>
                        <p className="is-size-6">
                          Taken {medicine.dailyIntake} per day
                        </p>
                      </div>
                    </MedicineBox>
                  )
                )}
                <AddMedicinesSection
                  showSearch={showSearch}
                  setShowSearch={setShowSearch}
                  medensureFormulary={medensureFormulary}
                />
                {state?.updatedPrescription?.medicines?.length === 0 &&
                  submitCount > 0 && (
                    <p className="has-text-danger help mt-1">
                      Your medicine list cannot be empty
                    </p>
                  )}
              </Section>
              {!isValid && submitCount > 0 && (
                <Message color="danger">
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              )}

              <ActionButtons
                back={{
                  label: "Back",
                  link: pageContext.backPath,
                }}
                submit={{
                  label: hasMultipleSegment ? "Next" : "Summary",
                  disabled:
                    state?.documents?.filter((document) => !document?.token)
                      ?.length > 0,
                }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default UpdatedPrescriptionForm
